import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Better Access To Clubs`}</h1>
    <div style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}><iframe src="https://www.loom.com/embed/5edd68d1a184426dbf1d703dd65dc76b" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></div>
    <p>{`I've been involved in clubs heavily since my freshman year. In fact, I even started an Irvine High club known as Irvine Lights with a few of my friends! I know what it's like not only being a member in a club, but also managing it. From joining clubs to sending in meeting minutes to the Clubs Commissioner, I would say I have a pretty strong knowledge about how the entire clubs system works. `}</p>
    <p>{`In my opinion, the Irvine High Clubs system needs a revamp. I interviewed over 50 freshman, sophomores, and juniors (some of them club leaders as well) and one of the most prominent responses I received was that they felt our clubs system this year could've been improved. Irvine High is home to over 100 clubs with different advisors, boards, and members. It is incredibly hard for students, especially freshman, to learn about which clubs they should join and why. As of right now, the primary mode of communication between clubs and students is Instagram. In the past this used to be on campus through the use of posters. Just like I mentioned in my article about making our school more technology focused , we need to digitize clubs. This starts with a few steps:`}</p>
    <ol>
      <li parentName="ol">{`We should set-up an Irvine High ASB website (if a website is not possible, we can also create a Canvas page). With the website, we should require every club to send in a 30 second video of them discussing their mission and what they do specifically. This should just be them talking to camera with photos or examples of what they do.`}
        <ul parentName="li">
          <li parentName="ul">{`Clubs should also have the option of attaching any kind of media along with their video, whether it be a sample lesson plan they teacher or a list of books they discuss.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`All of the videos, names, and descriptions should be posted in a grid format for people to watch and read and open. There should also be an automatically generated sign up form for each club. This will allow a more centralized way of managing sign ups. There are many websites, like `}<a parentName="li" {...{
          "href": "http://typeform.com"
        }}>{`Typeform`}</a>{`, that can be used to create multiple forms automatically. One of the most suggested item while interviewing freshman specifically, is that every club has a different sign up method, and some don't get back to you at all. Whether it be a Google Form, just joining a Remind, sending an email to the President, or showing up to a meeting, we need to create a more centralized way to facilitate club sign ups. This way everyone can receive emails about upcoming meetings or to join a Remind they need to.`}
        <ul parentName="li">
          <li parentName="ul">{`We can also accomplish this by integrating it into the school app I suggested in this article `}</li>
        </ul>
      </li>
    </ol>
    <p>{`Regardless if we can have a website that does what I described above or not, it's important we make the club registration system more accessible for all people, since a good portion of our school does not have social media to get updates. This would also not take place of our typical club fair, as we would try to have it in-person, but if not, doing a live fair where club leaders join a Zoom in and people can choose which breakout rooms they want to go in would be a wonderful idea. `}</p>
    <p>{`We should also make sure clubs are regularly doing activities or having meetings as inactive clubs flood social media and do not provide a lot of value on campus. I think we can accomplish this by having short phone calls or interviews with club leaders every semester to see what their status is and to learn what they need from ASB and student government. Whether it's help promoting an event or to raise money, it's important to support all student ventures!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      